exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buttons-tsx": () => import("./../../../src/pages/buttons.tsx" /* webpackChunkName: "component---src-pages-buttons-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-landing-page-template-tsx": () => import("./../../../src/templates/blogLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-landing-page-template-tsx" */),
  "component---src-templates-consultation-landing-page-template-tsx": () => import("./../../../src/templates/consultationLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-consultation-landing-page-template-tsx" */),
  "component---src-templates-consultation-template-tsx": () => import("./../../../src/templates/consultationTemplate.tsx" /* webpackChunkName: "component---src-templates-consultation-template-tsx" */),
  "component---src-templates-default-template-tsx": () => import("./../../../src/templates/defaultTemplate.tsx" /* webpackChunkName: "component---src-templates-default-template-tsx" */),
  "component---src-templates-domain-namespaces-template-tsx": () => import("./../../../src/templates/domainNamespacesTemplate.tsx" /* webpackChunkName: "component---src-templates-domain-namespaces-template-tsx" */),
  "component---src-templates-event-template-tsx": () => import("./../../../src/templates/eventTemplate.tsx" /* webpackChunkName: "component---src-templates-event-template-tsx" */),
  "component---src-templates-events-landing-page-template-tsx": () => import("./../../../src/templates/eventsLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-events-landing-page-template-tsx" */),
  "component---src-templates-find-a-dot-au-domain-name-template-tsx": () => import("./../../../src/templates/findADotAuDomainNameTemplate.tsx" /* webpackChunkName: "component---src-templates-find-a-dot-au-domain-name-template-tsx" */),
  "component---src-templates-policies-landing-page-template-tsx": () => import("./../../../src/templates/policiesLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-policies-landing-page-template-tsx" */),
  "component---src-templates-policy-archive-template-tsx": () => import("./../../../src/templates/policyArchiveTemplate.tsx" /* webpackChunkName: "component---src-templates-policy-archive-template-tsx" */),
  "component---src-templates-policy-template-tsx": () => import("./../../../src/templates/policyTemplate.tsx" /* webpackChunkName: "component---src-templates-policy-template-tsx" */),
  "component---src-templates-speeches-landing-page-template-tsx": () => import("./../../../src/templates/speechesLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-speeches-landing-page-template-tsx" */),
  "component---src-templates-speeches-template-tsx": () => import("./../../../src/templates/speechesTemplate.tsx" /* webpackChunkName: "component---src-templates-speeches-template-tsx" */),
  "component---src-templates-statements-landing-page-template-tsx": () => import("./../../../src/templates/statementsLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-statements-landing-page-template-tsx" */),
  "component---src-templates-statements-template-tsx": () => import("./../../../src/templates/statementsTemplate.tsx" /* webpackChunkName: "component---src-templates-statements-template-tsx" */),
  "component---src-templates-submissions-landing-page-template-tsx": () => import("./../../../src/templates/submissionsLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-submissions-landing-page-template-tsx" */),
  "component---src-templates-submissions-template-tsx": () => import("./../../../src/templates/submissionsTemplate.tsx" /* webpackChunkName: "component---src-templates-submissions-template-tsx" */),
  "component---src-templates-super-template-tsx": () => import("./../../../src/templates/superTemplate.tsx" /* webpackChunkName: "component---src-templates-super-template-tsx" */),
  "component---src-templates-whois-landing-page-template-tsx": () => import("./../../../src/templates/whoisLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-whois-landing-page-template-tsx" */)
}

